export interface SimpleUserAnswer {
  question: string;
  answer: string;
}

export interface UserInfo {
  name: string;
  email: string;
  phone: string;
}

export class UserQuestionnaire {
  public user!: UserInfo;
  public answers: Array<SimpleUserAnswer>;
  public correlationId!: string;

  constructor() {
    this.answers = new Array<SimpleUserAnswer>();
  }
}
