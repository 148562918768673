import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class beforeUnload extends Vue {

  private isEnabled = true;

  public preventBackAndClose (e) {
        e.preventDefault();
        e.returnValue = "Сохранить данные?";
  }

  created(): void {
    window.addEventListener("beforeunload", this.preventBackAndClose);
    this.isEnabled = true;
  }

  beforeDestroy() {
    if(this.isEnabled) {
        window.removeEventListener("beforeunload", this.preventBackAndClose);
    }
  }

  public disablePreventBackAndClose() {
    this.isEnabled = false;
    window.removeEventListener("beforeunload", this.preventBackAndClose);
  }
}