import Vue from "vue";
import Vuex from "vuex";
import "unfetch/polyfill";
import moment from 'moment';

import QuestionModel from "~/models/question";
import QuestionsDict from "~/models/questionDict";
import Answer from "~/models/anwser";
import {
  UserQuestionnaire,
  SimpleUserAnswer,
  UserInfo
} from "~/models/userQuestionnaire";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    questionsList: Array<QuestionModel>(),
    questionsMap: new QuestionsDict<QuestionModel>(),
    answersList: Array<Answer>(),
    finalQuestionnaire: new UserQuestionnaire(),
    promoCode: null
  },
  getters: {
    isTestLoaded(state): boolean {
      return state.questionsList.length > 0;
    },
    isTestStarted(state): boolean {
      return state.answersList.length > 0;
    },
    isTestPassed(state): boolean {
      return (
        state.answersList.length > 0 &&
        state.answersList.length === state.questionsList.length
      );
    }
  },
  mutations: {
    SET_PROMO_CODE(state, promoCode) {
      state.promoCode = promoCode
    },
    SET_STORE_FROM_LOCAL_STORAGE(state) {
      const dataFromLocalStorage = localStorage.getItem("store");
      if (dataFromLocalStorage) {
        this.replaceState(
          Object.assign(state, JSON.parse(dataFromLocalStorage))
        );
      }
    },
    SET_QUESTION_LIST(state, questionsList: Array<QuestionModel>) {
      state.questionsList = questionsList;
      questionsList.forEach(question => {
        state.questionsMap[question.blockId] = question;
      });
    },
    ADD_ANSWER(state, answer: Answer) {
      state.answersList.push(answer);
    },
    SET_USER_FOR_QUESTIONNAIRE(state, user: UserInfo) {
      state.finalQuestionnaire.user = user;
    },
    ADD_ANSWER_TO_QUESTIONNAIRE(state, answer: SimpleUserAnswer) {
      state.finalQuestionnaire.answers.push(answer);
    }
  },
  actions: {
    fetchQuestions({ commit }): Promise<void> {
      return fetch(`${process.env.VUE_APP_ENDPOINTPATH}/api/Questions?to=9999`)
        .then(r => r.json())
        .then(data => commit("SET_QUESTION_LIST", data.list));
    }
  }
});
