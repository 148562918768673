export enum SubmitState {
  INIT,
  LOADING,
  ERROR,
  DONE
}

export interface State {
  isLoading: boolean;
  isError: boolean;
  isDone?: boolean;
  text?: string;
}

interface Stateslist {
  [index: number]: State;
}

export const LoadingStates: Stateslist = {
  [SubmitState.INIT]: {
    isLoading: false,
    isError: false
  },
  [SubmitState.LOADING]: {
    isLoading: true,
    isError: false,
    text: "Загружаем тест"
  },
  [SubmitState.ERROR]: {
    isLoading: false,
    isError: true,
    text: "Произошла ошибка. Повторите позже"
  },
  [SubmitState.DONE]: {
    isLoading: false,
    isError: false,
    isDone: true
  }
};

export const SubmitStates: Stateslist = {
  [SubmitState.INIT]: {
    isLoading: false,
    isError: false,
    text: "Получить результаты"
  },
  [SubmitState.LOADING]: {
    isLoading: true,
    isError: false,
    text: "Получаем результаты"
  },
  [SubmitState.ERROR]: {
    isLoading: false,
    isError: true,
    text: "Произошла ошибка. Повторите позже"
  },
  [SubmitState.DONE]: {
    isLoading: false,
    isError: false,
    isDone: true
  }
};
