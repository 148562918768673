import Vue from "vue";
import VueRouter from "vue-router";
import store from "~/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: () => import("~/views/Welcome.vue"),
  },
  {
    path: "/intro",
    name: "intro",
    component: () => import("~/views/Intro.vue"),
    beforeEnter: (to:any, from:any, next:any) => {
      if(!from.name) {
        next({ name: "Welcome" })
      } else {
        next()
      }
    }
  },
  {
    path: "/test",
    name: "test",
    component: () => import("~/views/TestComponent.vue"),
    beforeEnter: (to:any, from:any, next:any) => {
      if(!from.name) {
        next({ name: "Welcome" })
      } else {
        next()
      }
    }
  },
  {
    path: "/congratulations",
    name: "congratulations",
    component: () => import("~/views/Congratulations.vue"),
    beforeEnter: (to:any, from:any, next:any) => {
      if(!from.name) {
        next({ name: "Welcome" })
      } else {
        next()
      }
    }
  },
  {
    path: "/result",
    name: "result",
    component: () => import("~/views/GetResult.vue"),
    beforeEnter: (to:any, from:any, next:any) => {
      if(!from.name) {
        next({ name: "Welcome" })
      } else {
        next()
      }
    }
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("~/views/Agreement.vue")
  },
  {
    path: "/thank-you",
    name: "thanks",
    component: () => import("~/views/Thanks.vue"),
    beforeEnter: (to:any, from:any, next:any) => {
      if(!from.name) {
        next({ name: "Welcome" })
      } else {
        next()
      }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
