import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import { Component } from "vue-property-decorator";
import vmodal from 'vue-js-modal'
import VueProgress from "vue-progress";
import { ActivityService } from "./services/ActivityService"

import "buefy/dist/buefy.css";
import "~/assets/styles/app.css";
import "~/assets/styles/variables.css";
import "~/assets/styles/quiz.css";
import "~/assets/styles/final_page.css";
import "~/assets/styles/main.scss";
Component.registerHooks(["validations"]);

Vue.use(Vuelidate);
Vue.use(VueProgress);
Vue.use(vmodal);

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

Vue.config.productionTip = false;

export const activity = new ActivityService();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
