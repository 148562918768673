import { v4 as uuidv4 } from 'uuid';

export class ActivityService {
  private id: string;

  constructor() {
    this.id = uuidv4();
  }

  public getID() {
    return this.id;
  }

  public async sendActivity(activityType: number) {
    try {
      await fetch(`${process.env.VUE_APP_ENDPOINTPATH}/api/Activity`, {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({ type: activityType, correlationId: this.id })
      });
    } catch (error) {
      console.debug(error);
    }
  }
}